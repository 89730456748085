* {
    margin: 0;
    padding: 0;

    background: #FAFEFF;
    /*background: red;*/
}

@font-face {
    font-family: 'Ubuntu_bolt';
    src: url('./assets/fonts/Ubuntu_bolt.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu_medium';
    src: url('assets/fonts/Ubuntu_medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu_light';
    src: url('./assets/fonts/Ubuntu_light.ttf') format('truetype');
}

@font-face {
    font-family: 'Lora_Medium';
    src: url('./assets/fonts/Lora_Medium.ttf') format('truetype');
}

.splide.is-overflow.is-initialized.splide--slide.splide--ltr.splide--draggable.is-active {
    width: 300px;
    margin: 0 auto;
}

.splide__pagination__page {
    width: 8px;
    height: 8px;

    background: #769FCD;
    opacity: 0.32;
    border-radius: 50px;
}

.splide__pagination__page.is-active {
    background: #769FCD;
    opacity: 1;
}

.splide__pagination.splide__pagination--ltr {
    bottom: -22px;
}
